import React from 'react'
// import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';

const QuotePdf = ({children}) => {
    // Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'columns',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });
  
  // Create Document Component
  return (
    <>
    {/* <PDFViewer width={'100%'} height={'100%'}>

        <Document>
        <Page size="A4" style={styles.page}>
            <View fixed style={styles.section}>
            <Text>header #1</Text>
            </View>
            <View  style={styles.section}>
            {children}
            </View>
            
        </Page>
        </Document>
    </PDFViewer> */}
    </>
  );



}

export default QuotePdf