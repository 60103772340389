import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import Blocks from 'editorjs-blocks-react-renderer';
// import Blocks from 'editorjs-blocks-react-renderer';

const InvoiceFooter = ({
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_VAT_PERCENT,
    SETTING_SHOW_TERMS_ON_PRINT,
    isDelivery,
    currentLang,
    SETTING_INV_CURRENCY,
    formatter,
    invNotes
}) => {

    const [t] = useTranslation('global')

    // const _notes = () => invNotes ? <Blocks data={invNotes} config={{
    //     code: {
    //       className: "language-js"
    //     },
    //     delimiter: {
    //       className: "border border-2 w-16 mx-auto"
    //     },
    //     embed: {
    //       className: "border-0"
    //     },
    //     header: {
    //       className: "font-bold"
    //     },
    //     image: {
    //       className: "w-full max-w-screen-md",
    //       actionsClassNames: {
    //         stretched: "w-full h-80 object-cover",
    //         withBorder: "border border-2",
    //         withBackground: "p-2",
    //       }
    //     },
    //     list: {
    //       className: "list-inside"
    //     },
    //     paragraph: {
    //       className: "text-base text-opacity-75",
    //       actionsClassNames: {
    //         alignment: "text-{alignment}", // This is a substitution placeholder: left or center.
    //       }
    //     },
    //     quote: {
    //       className: "py-3 px-5 italic font-serif"
    //     },
    //     table: {
    //       className: "table-auto"
    //     }
    //   }} />: 'fff';

      
    useEffect(()=>{
      console.log(invNotes);
    },[invNotes]);

    return (
        <>

{ !isDelivery &&
        <div > 
            <div className='my-3 border rounded text-xs'>
                
                {
                    discountAmount ? 
                    <div className='flex-col'>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span>الإجمالي قبل الخصم </span>
                                <span>TOTAL BEFORE DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{ formatter.format(totalAmount)  } </div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span> الخصم </span>
                                <span>DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{formatter.format(discountAmount)}</div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                            <span> الإجمالي بعد الخصم </span>
                            <span> TOTAL AFTER DISCOUNT </span>
                        </div>
                            <div className=''>{formatter.format(totalAmount - discountAmount )} </div>
                        </div>
                    </div> : 
                    <div className='flex justify-between px-3 py-2 border-b'>
                        <div className='flex flex-col'>
                            <span>الإجمالي الفرعي </span>
                            <span>SUB TOTAL</span>
                        </div>
                        <div className='flex flex-col justify-center '>{formatter.format(totalAmount)}  </div>
                    </div>
                }
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span> ضريبة القيمة المضافة ({SETTING_INV_VAT_PERCENT}%)</span>
                            <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>
                    <div className='flex flex-col justify-center '>{formatter.format(vatAmount)} </div>
                </div>
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span>الإجمالي شامل الضريبة </span>
                            <span>TOTAL</span>
                        </div>
                    <div className='font-bold flex flex-col justify-center '>{formatter.format(netAmount)}  </div>
                </div>
            </div>
            {/* <div className='pt-2 flex flex-col justify-center items-center'>
                            { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                                <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                                <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                                
                            }
                        </div> */}
        </div>}
        {invNotes && <div className='p-2 my-4' > 
            {/* <div className='font-bold '>NOTES </div> */}
            { JSON.parse(invNotes) && <Blocks data={JSON.parse(invNotes) } /> } </div>}
        { SETTING_SHOW_TERMS_ON_PRINT === 'true' && invTerms && !isDelivery && <div className='text-xs p-2'>
            {/* <div className='font-bold '>- {t('invoice.ConditionAndTerms')} / Condition And Terms</div> */}
            <div className='font-bold '>CONDITION AND TERMS </div>
            <div className='pt-1  '> { JSON.parse(invTerms) &&  <Blocks data={JSON.parse(invTerms) } /> } </div>
        </div>}
        <div className='p-2 mt-8 font-bold  '>Signature and approval </div>
        <div className='px-2    '> ------------------------------------------ </div>
        
        </>
    )
}

export default InvoiceFooter