import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    customer,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    formatter
    
}) => {
    console.log('customer => ',customer);
    const [t] = useTranslation('global')
    const invProductsList = invProducts.map((prod) => (
        
            prod.product_id === "section" ? 
            <div key={prod.id} className={`flex justify-between border-b `}>
                <div className='flex-1 p-2 border-e font-bold bg-slate-100'>{prod.product_name} </div>
            </div>
            :
            <div key={prod.id} className={`flex justify-between border-b  ${ prod.is_returned && 'bg-orange-50'}`}>
                <div className='flex-1 p-2 border-e'>{prod.product_name} {prod.is_returned && <span className='font-bold text-orange-300'>[مرتجع]</span> } </div>
                {!isDelivery && <div className='w-20 text-center  border-e p-2'>{formatter.format(prod.price)} </div>}
                <div className='w-16 text-center border-e p-2'>{prod.qty}</div>
                {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.total_price)} </div>}
                {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_discount)} </div>}
                {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total)} </div>}
                {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_vat).slice(0,-6)} </div>} */}
                {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total_with_vat)} </div>}
            </div>
        
    ));


    return (
        <div className='static'>
            <div className='text-xs py-3' >
            { customerName && <div  className='flex justify-between text-xs rounded-t-lg border  pt-2 mt-3 '>
                <div className='border-l border-r flex-1  items-center justify-between gap-3 p-2  '> 
                <div className='flex flex-col text-center'>
                    <span> اسم العميل </span>
                    <span> CUSTOMER NAME</span>
                    <span className='font-bold'>{customer?.fullname[0]?.text || customer?.fullname[1]?.text }</span>
                </div>
                </div>
                <div className='border-l border-r flex-1  items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span> الرقم الضريبي للعميل</span>
                        <span> CUSTOMER VAT NO.</span>
                    <span className='font-bold'>{customer?.customerVatNo}</span>
                    </div>
                </div>
                
                <div className=' flex-1  items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span> عنوان العميل</span>
                        <span>CUSTOMER ADDRESS </span>
                    <span className='font-bold'>{customer?.customerAddresses[0]?.desc}</span>
                    </div>
                </div>
            </div>}
                {/* <div className='flex justify-between text-xs px-1 pb-3 pt-1'>
                    {customerName && <div>{t('invoice.Customer')}: {customerName}</div>}
                    {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>}
                </div> */}
                <div  className='flex mt-5 justify-between items-center p-3 bg-slate-10 font-bold border-t border-r border-l rounded-t'>
                    <div className='flex-1 text-center  flex flex-col'>
                        <span>اسم المنتج - الخدمة</span>
                        <span>PRODUCT / SERVICE NAME</span>
                    </div>
                    {
                    !isDelivery && 
                        <div className='w-20 text-center flex flex-col '>
                            <span>سعر الوحدة</span>
                            <span>PRICE UNIT</span>
                        </div>
                    }
                    <div className='w-16 text-center flex flex-col'>
                        <span>{t('invoice.quantity')}</span>
                        <span>QUANTITY</span>
                    </div>
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>الخصم</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الخصم </span>
                        <span>T.A DISCOUNT</span>
                        </div>}
                    {/* {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة ({SETTING_INV_VAT_PERCENT}%)</span>
                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>} */}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الضريبة</span>
                        <span>T.A VAT</span>
                        </div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
            
        </div>
    )
}

export default InvoiceBody