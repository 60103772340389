import React, { useState,useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {  useTranslation } from 'react-i18next';
import {AiOutlineSave} from 'react-icons/ai'
import {RiQuoteText} from 'react-icons/ri'
import {updateSettings} from '../../../store/settingSlice'

import {useUser,usePost,useInvoice,useGenQuoteNo,useLogs} from '../../../hooks';
import {MwToast,MwSpinner, MwButton} from '../../ui';
import CreateProduct from '../../Products/CreateProduct';
import CreateCustomer from '../../Customers/CreateCustomer';
import CreateSalesman from '../../Salesmans/CreateSalesman';
import {getAllSalesman} from '../../../services/salesmanService';
import {getAllCustomers} from '../../../services/customerService';
import InvFormFooter from './InvFormFooter';
import InvFormHeader from './InvFormHeader';
import InvFormBody from './InvFormBody';
import MwSpinnerButton from '../../ui/MwSpinnerButton';
import SideModal from '../../ui/SideModal';
import { amountFormat } from '../../../utils/invoceHelper';


const InvForm = ({tenantId,tenantUsername}) => {
    const [t] = useTranslation('global')
    const dispatch = useDispatch()
    const [disabledDiscount,setDisabledDiscount] = useState(false);

    // const {currentLangId,currentCompanyId,currentBranchId} = useCurrent();
    const companySelected  = useSelector((state)=>state.currentCompany)
    const branchSelected = useSelector((state)=>state.currentBranch)
    const {
            SETTING_QUOTE,
            initalProductItem,
            calcSumPrice,
            calcSumNetTotal,
            toFloat,
            calcVat,
            formatter,
            } = useInvoice();

    const INV_CURRENCY = SETTING_QUOTE?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'
    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    const {newInvNo,loading:loadingGenInvoiceNo} = useGenQuoteNo();
    const navigate = useNavigate();
    const {getUser} = useUser();
    console.log(getUser._id);
    const { data:invDataPost, loading:invLoadingPost, error:invErrorPost,postData:invPost} = usePost(); 
    const  [formErrors,setFormErrors] = useState({});
    // products
    const [reloadProductList,setReloadProductList] = useState(false)
    const [showProductModal,setShowProductModal] = useState(false);
    // Customer
    const [customers,setCustomers] = useState([]); 
    const [showCreateCustomerModal,setShowCreateCustomerModal] = useState(false);
    const [isAddedCustomer,setIsAddedcustomer] = useState(false); 
    // Salesman
    const [salesmans,setSalesmans] = useState([]); 
    const [showCreateSalesmanModal,setShowCreateSalesmanModal] = useState(false);
    const [isAddedSalesman,setIsAddedSalesman] = useState(false); 
    // Toast
    const [showToast,setShowToast] = useState(false);
    const [msgToast,setMsgToast] = useState('');
    // Invoice input fields
    const [invNo,setInvNo] = useState('');
    const invRef = useRef(null);
    const invTitle = useRef(null);
    const invDate = useRef(null);
    const invDateDue = useRef(null);
    const invCurrency = useRef(null);
    const [customerSelected,setCustomerSelected] = useState();
    const [salesmanSelected,setSalesmanSelected] = useState();
    const [invNotes,setInvNotes] = useState('');
    const [invTerms,setInvTerms] = useState('');
    const [havePromoCode,setHavePromoCode] = useState(false);
    const [promoCode,setPromoCode] = useState('');
    const [invTotalAmount,setInvTotalAmount] = useState(0);
    const [totalAmountWithDiscount,setTotalAmountWithDiscount] = useState(0);
    const [discount,setDiscount] = useState(0)
    const [vat,setVat] = useState('')
    const [netTotal,setNetTotal] = useState('')
    // const [productList,setProductList] = useState([initalProductItem]); 
    const [productList,setProductList] = useState([]); 
    const [tagList,setTagList] = useState([]);
    // Promo Code
    const promoCodeHandler =() => {

    }
    // Product Functions
    const showModalProductHandle = () => {
        setShowProductModal(true);
    }
    const onProductCreated = () => {
        setReloadProductList(true);
    }

    // Customer Functions
    const onCustomerCreated = () => {
        setIsAddedcustomer(true);
        
        // setMsgToast('Customer Has been added...')
        // setShowToast(true);
    }
    const onClickNewCustomer = () => {
        setShowCreateCustomerModal(true);
        setIsAddedcustomer(false);
    }
    


    // Salesman Functions
    const onSalesmanCreated = () => {
        setIsAddedSalesman(true);
        // setMsgToast('Salesman Has been added...')
        // setShowToast(true);
    }
    const onClickNewSalesman = () => {
        setShowCreateSalesmanModal(true);
        setIsAddedSalesman(false);
    }
    const calcSum = () => {
        if(productList){
            return calcSumPrice(productList)
        }
        
    }

    const invSumHandler = () => {
        if(productList){

            console.log('invSumHandler')
            const sum = calcSum();
            const netTotal = calcSumNetTotal(productList);
            const _discountItems = sum - netTotal;
            
            _discountItems > 0 ? setDisabledDiscount(true) : setDisabledDiscount(false)
            if(disabledDiscount && _discountItems >= 0){
                setDiscount(amountFormat(_discountItems) ) 
            }
            const _totalAmountWithDiscount = sum - discount;
            setInvTotalAmount(sum);
            setTotalAmountWithDiscount(_totalAmountWithDiscount);
            vatHandler(_totalAmountWithDiscount);
            console.log('total => ',invTotalAmount,totalAmountWithDiscount,)
        }
    }

    const vatHandler = (total=totalAmountWithDiscount) => {
        const calcvat = calcVat(total)
        setVat(calcvat)
        // if(SETTING_QUOTE.PRICE_INCLUDE_VAT === 'true'){
        //     setNetTotal(toFloat(total))
        // }else{
            const nt = parseFloat(calcvat) + parseFloat(total);
            setNetTotal(nt)
        //}
    }

    const discountHandler = () => {
        invSumHandler();
    }

    const netTotalHandler = () => {
        const invTotal  = totalAmountWithDiscount   ;
        const invDiscount = toFloat(discount,false) ;  
        const invVat = toFloat(vat,false) ;  
        const net = toFloat(invTotal - invDiscount + invVat);
        setNetTotal(net);
    }

    const discountOnchange = (value) => {
        
        const sum = calcSum();
        let discountValue;
        if(value.includes('%')){
            invSumHandler();
            discountValue =  (toFloat( toFloat(sum,false) * toFloat(value.replace('%', ''),false) /100 ));
            console.log(discountValue);
        }else{
            discountValue = value
        }
        console.log('netTotal',netTotal);
        setDiscount(()=> discountValue );
    }

    const validateForm = () => {
        setFormErrors({})
        let errors = {};
        if(!invDate?.current?.value){
            errors.invDate = 'Date is required';
        }
        if(!invDateDue?.current?.value){
            errors.invDateDue = 'Date Due is required';
        }
        if(!!productList[0].productId === false){
            errors.productList = 'you must select a product';
        }

        if(!netTotal){
            return;
        }

        if(Object.keys(errors).length > 0){
            setFormErrors({...errors});
            return false;
        }
        return true;
    }

    const onsubmitHandler = (e) => {
        console.log('onsubmitHandler', e);
        e.preventDefault()
        if(e.key === 'Enter') return ;
        // validate form 
        if(!validateForm())return;
        let productListHandler = [];
        productList.map((product)=>{
            if (product && product.productName !=='Deleted' ){
                productListHandler.push(  {
                    product_id : product.productId,
                    product_name : product.productName,
                    unit_id : 1,
                    qty : product.qty ,
                    // price: (parseFloat(product.price)/parseInt(product.qty)),
                    price: product.priceUnit || 0,
                    total_price : product.price || 0,
                    product_discount : product.productDiscount || 0,
                    product_net_total : product.productNetTotal || 0,
                    product_vat : product.productVat || 0,
                    product_net_total_with_vat: product.productNetTotalWithVat || 0,
                });
            }
        });
        const _data = {
            inv_no: invNo,
            inv_ref:invRef?.current?.value || '',
            inv_date:invDate?.current?.value,
            inv_date_due:invDateDue?.current?.value,
            customer_id:customerSelected?.value,
            customer_name:customerSelected?.label,
            salesman_id:salesmanSelected?.value,
            salesman_name:salesmanSelected?.label,
            total_amount: invTotalAmount,
            discount_amount: discount,
            // promo_code_id:
            // promo_code:
            vat_amount: vat,
            net_amount: netTotal,
            title: invTitle?.current?.value || '',
            notes: JSON.stringify(invNotes),
            terms: JSON.stringify(invTerms),
            is_paid: false,
            type_id:1,
            status_id:1,
            tenant_id: tenantId,
            company_id: companySelected.value,
            branch_id: branchSelected.value,
            created_by: getUser?._id || 0,
            inv_tags:JSON.stringify(tagList),
            inv_details:productListHandler
        }
        invPost(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes`,_data)
    };

    const getcustomerHandler = async () => {
        const res = await getAllCustomers(tenantId,companySelected.value,branchSelected.value)
        setCustomers(res);
    }

    const getSalesmanHandler = async () => {
        const res = await getAllSalesman(tenantId,companySelected.value,branchSelected.value);
        setSalesmans(res);
    }

    const setLogsHandler =  () => {
        const _data = {
            moduleName: "SALES",
            resourceName: "QUOTES",
            eventName:"ADD",
            logContentEn: `Add new Quote No: ${invDataPost.inv_no}`,
            logContentAr: ` انشاء عرض سعر جديد : ${invDataPost.inv_no}`,
        }
        
        postDataHandler(_data);
        
    }
    
    useEffect(()=>{
        if(resultLogsPost)
        navigate(`/${tenantUsername}/quotes`);
    },[resultLogsPost]);

    useEffect(()=>{
        if(invDataPost){
            setLogsHandler();
        }
    },[invDataPost]);

    useEffect(() => {   
        getcustomerHandler();    
        getSalesmanHandler();
    },[]);

    useEffect(() => {
        if(isAddedCustomer){
            getcustomerHandler();        
        }
    },[isAddedCustomer]);

    useEffect(() => {
        if(isAddedSalesman){
            getSalesmanHandler();       
        }
    },[isAddedSalesman]);

    useEffect(()=>{
        if(productList ){  
            invSumHandler();
            dispatch(updateSettings({
                typeSetting : 'invoice', value :{
                    INV_ITEMS_ROW_NUMBER : productList?.length 
                }
            }))
        }
    },[productList]);

    useEffect(()=>{
        netTotalHandler();
        vatHandler();
    },[invTotalAmount])

    useEffect(() => {
        if(productList){
        discountHandler();
        }
    },[discount])

    useEffect(() => {
        if(!loadingGenInvoiceNo){
            setInvNo(newInvNo)
        }
    },[loadingGenInvoiceNo]);


    return (
        <>
            { showToast && <MwToast 
                msg={msgToast} 
                isShow={showToast}
                setIsShow = {setShowToast}
                /> 
            }

            <SideModal title="Create New Product" onShow={showProductModal} setOnShow={setShowProductModal} >
                <CreateProduct onProductCreated={onProductCreated} setReloadProductList={setReloadProductList}  />
            </SideModal>

            {/* <MwModal title="Create New Product" onShow={showProductModal} setOnShow={setShowProductModal} >
                <CreateProduct onProductCreated={onProductCreated} setReloadProductList={setReloadProductList}  />
            </MwModal> */}

            <SideModal title="Create New Customer" onShow={showCreateCustomerModal} setOnShow={setShowCreateCustomerModal} >
                <CreateCustomer onCustomerCreated={onCustomerCreated}  />
            </SideModal>

            <SideModal title="Create New Salesman" onShow={showCreateSalesmanModal} setOnShow={setShowCreateSalesmanModal} >
                <CreateSalesman onSalesmanCreated={onSalesmanCreated}  />
            </SideModal>
            {loadingGenInvoiceNo &&  <MwSpinner/> }
            {!loadingGenInvoiceNo && 
            <form  onSubmit={onsubmitHandler} >
                <div className='flex items-center justify-between mb-3' >
                    <div className=' flex gap-1 px-2 text-sm text-gray-400 '>
                        <RiQuoteText size={18} />
                        انشاء عرض سعر جديد</div>
                    { invLoadingPost ? <MwSpinnerButton/> : 
                        <MwButton
                        actionType='submit'
                        inGroup = {true}
                                type = 'saveBtn'
                            > <AiOutlineSave size={16}/> حفظ عرض السعر </MwButton> 
                    }
                </div>
                <div className='flex flex-col h-full justify-between  bg-slate-50 p-2 rounded-lg'>
                    <InvFormHeader
                            invNo = {invNo}
                            setInvNo = {setInvNo}
                            invRef = {invRef}
                            invTitle = {invTitle}
                            invDate = {invDate}
                            invDateDue = {invDateDue}
                            invCurrency = {invCurrency}
                            customers = {customers}
                            onClickNewCustomer = {onClickNewCustomer}
                            salesmans = {salesmans}
                            onClickNewSalesman = {onClickNewSalesman}
                            customerSelected = {customerSelected} 
                            setCustomerSelected = {setCustomerSelected}
                            salesmanSelected = {salesmanSelected}
                            setSalesmanSelected = {setSalesmanSelected}
                            formErrors = {formErrors}
                    />
                    <InvFormBody 
                        initalProductItem = {initalProductItem}
                        reloadProductList = {reloadProductList}
                        showModalProductHandle = {showModalProductHandle}
                        productList = {productList}
                        setProductList ={setProductList}
                        formErrors = {formErrors}
                        invNotes = {invNotes}
                        setInvTerms = {setInvTerms}
                        setInvNotes = {setInvNotes}
                        /> 
                    <InvFormFooter
                        invNotes = {invNotes}
                        setInvNotes = {setInvNotes}
                        invTotalAmount = {invTotalAmount} 
                        totalAmountWithDiscount = {totalAmountWithDiscount}
                        invCurrency = {invCurrency}
                        promoCode = {promoCode}
                        setPromoCode = {setPromoCode}
                        discount = {discount}
                        vat = {vat}
                        netTotal = {netTotal}
                        havePromoCode = {havePromoCode}
                        setHavePromoCode = {setHavePromoCode}
                        promoCodeHandler = {promoCodeHandler}
                        discountOnchange = {discountOnchange}
                        SETTING_PRICE_INCLUDE_VAT = {SETTING_QUOTE.PRICE_INCLUDE_VAT}
                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                        tagList = {tagList} 
                        setTagList = {setTagList}
                        disabledDiscount ={disabledDiscount}
                        formatter ={formatter}
                    />
                </div>
            </form> }
        </>
    )
}

export default InvForm